import { ChevronRightIcon, ChevronLeftIcon } from "lucide-react";
import { useEffect, useState, useRef } from "react";
import { InstallProps } from "../InstallScreen";

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return windowDimensions;
}

export default function Carousel(props: InstallProps) {
  const carouselRef = useRef<HTMLDivElement | null>(null);
  const imageRef = useRef<HTMLDivElement | null>(null);
  const { width } = useWindowDimensions();
  const [imageWidth, setImageWidth] = useState(0);

  const images = props.screenshots.map((s) => s.toString());

  const handleScroll = (direction: "left" | "right") => {
    if (carouselRef.current && imageWidth > 0) {
      const scrollAmount = direction === "left" ? -imageWidth : imageWidth;
      carouselRef.current.scrollBy({ left: scrollAmount, behavior: "smooth" });
    }
  };

  const getItemsPerView = () => {
    if (width < 640) return 1;
    if (width < 1024) return 2;
    return 3;
  };

  const itemsPerView = getItemsPerView();

  // Calculate image width dynamically when the component mounts or when window size changes
  useEffect(() => {
    if (imageRef.current) {
      const itemWidth = imageRef.current.offsetWidth;
      setImageWidth(itemWidth);
    }
  }, [width, itemsPerView]); // Recalculate when the window width or itemsPerView changes

  return (
    <div className="relative w-full max-w-5xl mx-auto">
      {/* Carousel Container */}
      <div
        ref={carouselRef}
        className="flex overflow-x-scroll snap-x snap-mandatory scrollbar-hide"
        style={{ scrollbarWidth: "none", msOverflowStyle: "none" }}
      >
        {images.map((image, index) => (
          <div
            // biome-ignore lint/suspicious/noArrayIndexKey: <explanation>
            key={index}
            ref={index === 0 ? imageRef : null}
            className="flex-shrink-0 px-2"
            style={{
              width: `${100 / itemsPerView}%`,
            }}
          >
            <img
              src={image}
              alt={`Screenshot ${index + 1}`}
              className="w-full h-auto object-cover rounded-lg"
            />
          </div>
        ))}
      </div>
      {/* Arrows */}
      <button
        className="absolute top-1/2 left-0 transform -translate-y-1/2 bg-white p-2 rounded-full shadow-lg"
        type="button"
        onClick={() => handleScroll("left")}
      >
        <ChevronLeftIcon className="w-6 h-6" />
      </button>
      <button
        className="absolute top-1/2 right-0 transform -translate-y-1/2 bg-white p-2 rounded-full shadow-lg"
        type="button"
        onClick={() => handleScroll("right")}
      >
        <ChevronRightIcon className="w-6 h-6" />
      </button>
    </div>
  );
}
