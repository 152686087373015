import { DownloadIcon } from "lucide-react";
import { usePWAInstall } from "react-use-pwa-install";
import { ReactNode, useState } from "react";

import Carousel from "./Installer/Carousel";
import Modal from "./Installer/Modal";
import platformHint from "./Installer/platform";

export interface InstallProps {
  name: string;
  logo: string;
  url: string;
  screenshots: string[];
  description: ReactNode;
  information: ReactNode;
}

export default function InstallScreen(props: InstallProps) {
  const hint = platformHint();
  const install = usePWAInstall() ?? (() => setOpen(true));
  const [open, setOpen] = useState(false);

  return (
    <main className="min-h-screen bg-gray-50 overflow-hidden">
      {/* Modal */}
      <Modal
        open={open}
        title="Install"
        description={hint}
        onClose={() => setOpen(false)}
      />
      {/* Header */}
      <div className="w-full max-w-6xl mx-auto px-4 sm:px-6 lg:px-8 py-4 sm:py-6 lg:py-8 space-y-4 sm:space-y-8 lg:space-y-12">
        <header className="flex flex-col sm:flex-row sm:justify-between sm:items-center text-center sm:text-left">
          <div className="flex flex-col sm:flex-row sm:items-center gap-4">
            {/* Logo */}
            <img
            src={props.logo}
            alt={`${props.name} Logo`}
            className="w-12 h-12 sm:w-16 sm:h-16 lg:w-24 lg:h-24 rounded-2xl shadow-lg mx-auto sm:mx-0"
            />
            {/* Name */}
            <div>
              <h1 className="text-xl sm:text-2xl lg:text-3xl font-bold">
                {props.name}
              </h1>
              <a
                className="text-gray-600 text-sm sm:text-base hover:underline"
                href={props.url}
              >
                {props.url}
              </a>
            </div>
          </div>
          {/* Install button */}
          <button
            className="w-full sm:w-auto min-w-[200px] mt-4 sm:mt-0 px-6 sm:px-8 py-3 sm:py-4 text-white bg-teal-500 rounded-full flex items-center justify-center gap-2 shadow-md hover:bg-teal-600 active:bg-teal-700 transition-colors text-sm sm:text-base"
            type="button"
            onClick={install}
          >
            <DownloadIcon className="w-4 h-4 sm:w-5 sm:h-5" />
            Install
          </button>
        </header>
      </div>
      {/* Image carousel */}
      <Carousel {...props} />
      {/* Description */}
      <section className="w-full max-w-6xl mt-8 mx-auto px-4 sm:px-6 lg:px-8 py-4 sm:py-6 lg:py-8 space-y-4 sm:space-y-8 lg:space-y-12 bg-white rounded-xl sm:rounded-2xl shadow-md p-4 sm:p-6 lg:p-8">
        <h2 className="text-xl sm:text-2xl font-bold mb-3 sm:mb-4">
          About {props.name}
        </h2>
        <p className="text-gray-600 text-sm sm:text-base">
          {props.description}
        </p>
        <h2 className="text-xl sm:text-2xl font-bold mb-3 sm:mb-4">Availability</h2>
        <p className="text-gray-600 text-sm sm:text-base">
          {props.information}
        </p>
      </section>
    </main>
  );
}
