import { ReactNode } from "react";

export interface ModalProps {
  open: boolean;
  title: string;
  description: ReactNode;
  onClose: () => void;
}

export default function Modal(props: ModalProps) {
  if (!props.open) return null;

  return (
    <div
      className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50"
      onClick={(e) => {
        if (e.target === e.currentTarget) props.onClose();
      }}
    >
      <div className="bg-white rounded-xl shadow-md p-6 w-full max-w-lg">
        <h2 className="text-xl font-bold mb-4">{props.title}</h2>
        <p className="text-gray-600">{props.description}</p>
      </div>
    </div>
  );
}
